<template>
  <div class="genotype-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <GenotypeInput
          :errors="errors"
          :initial="genotype.value"
          :onChange="onGenotypeChange"
        />
      </vs-card>
      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button
        color="primary"
        type="filled"
        :disabled="!isValid"
        @click="onFormSubmit()"
      >
        {{ $l.get("dictionary.submit-new") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import GenotypeInput from "@/application/components/dna/GenotypeInput";

export default {
  name: "genotype-form",
  props: ["genotype", "onSubmit"],
  components: { GenotypeInput },
  data() {
    return {
      errors: [],
      isValid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      if (!this.isValid) return;
      this.onSubmit();
    },
    onGenotypeChange(snpId, alleles, value) {
      this.genotype.value = value;
      this.genotype.snp = snpId;
      this.genotype.alleles = alleles;
      this.isValid = snpId != null;
    }
  }
};
</script>

<style lang="scss">
.genotype-form .vs-con-input-label {
  width: 100% !important;
}

.genotype-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.genotype-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.genotype-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.genotype-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .genotype-form .vs-input {
//   margin: 15px;
// }
</style>
