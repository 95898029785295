<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("genotype-edit.header") }}</h3>
      <GenotypeForm
        :genotype="data.genotype"
        :onSubmit="onSubmit"
        v-if="data && data.genotype"
      />
    </div>
  </div>
</template>

<script>
import GenotypeEditScreenController from "@/adapters/controllers/screen-genotype-edit";
import InitGenotypeEditInteractor from "@/business/genotype-edit/init-genotype-edit";
import SubmitFormInteractor from "@/business/genotype-edit/submit-form";
import GenotypeForm from "@/application/components/dna/GenotypeForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "genotype-edit",
  components: { GenotypeForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initGenotypeEdit: null,
        isLoading: false
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenotypeEditScreenController);

    //{ INTERACTORS
    this.interactors.initGenotypeEdit = this.$injector.get(
      InitGenotypeEditInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initGenotypeEdit.handle(id);
  },
  methods: {
    setData(data) {
      this.data = data;
      const genotype = data.genotype;
      if (genotype) {
        genotype.value = `${genotype.snp.rsid}(${genotype.alleles})`;
      }
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.data.genotype);
    }
  }
};
</script>

<style lang="scss"></style>
